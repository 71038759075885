import React from 'react';
import PropTypes from 'prop-types';

import { makeInputProps } from './helpers';
import { Input } from '../../../shared';
import { ConstraintInput, DataframeInput } from './SpecialInputs';

export default function ModelInputDisplay({ input, disabled, inputProps, handleChange }) {
    if (input.dataType === 'dictionary') {
        return (
            <ConstraintInput
                inputObj={input}
                updateConstraint={(updateObject) => handleChange(input.varName, updateObject)}
                readOnly={disabled}
                {...makeInputProps(input)}
                {...inputProps}
            />
        );
    }
    if (input.display?.inputType === 'dataframe') {
        return (
            <DataframeInput
                disabled={disabled}
                onChange={(updateObject) => handleChange(input.varName, updateObject)}
                {...makeInputProps(input)}
                {...inputProps}
            />
        );
    }
    return <Input {...makeInputProps(input)} disabled={disabled} onChange={handleChange} {...inputProps} />;
}

ModelInputDisplay.propTypes = {
    input: PropTypes.shape({
        dataType: PropTypes.string,
        value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
        varName: PropTypes.string,
        display: PropTypes.shape({
            inputType: PropTypes.string,
            config: PropTypes.shape({}),
        }),
    }).isRequired,
    disabled: PropTypes.bool,
    inputProps: PropTypes.shape({}),
    handleChange: PropTypes.func,
};

ModelInputDisplay.defaultProps = {
    disabled: false,
    inputProps: {},
    handleChange: () => {},
};
